import React from 'react';
import loadable from '@loadable/component';
import AgreementsPageLoading from './AgreementsPageLoading';

const AgreementsPage = loadable(
  () => import(/* webpackChunkName: "agreements-page-chunk" */ './AgreementsPage'),
  {
    fallback: (
      <AgreementsPageLoading />
    ),
  }
);

export default AgreementsPage;