import React from 'react';
import PropTypes from 'prop-types';
import SvgIcon from '../SvgIcon';

const LogoBrandIcon = (props) => {
  const {
    color = '#3F21B3',
    width = 184,
    height = 48,
    wrapCssClass = {},
    isColumn = false,
    isLogo = false,
  } = props;

  if (isLogo) {
    return (
      <SvgIcon
        title="clevergig"
        height={height}
        width={width}
        css={wrapCssClass}
        viewBox="0 0 688 1024"
      >
        <path
          fill={color}
          d="M561.329 37.28c-97.863-106.308-53.944 48.516-219.705 48.516s-121.841-154.824-219.705-48.516c-76.127
          82.697-121.841 176.775-121.841 292.356 0 0 0.122 0.184 0.122 0.281 1.505 8.103 41.715 216.34 341.599
          300.959 5.586-0.525 341.283-40.64 341.283-301.23h0.079c0.018-115.583-45.72-209.661-121.824-292.356zM341.378
          516.875c-185.56 0-264.497-100.862-264.497-187.343s70.111-156.59 156.59-156.59c41.837 0 79.802 16.442 107.89
          43.167 28.087-26.731 66.071-43.167 107.89-43.167 86.482 0 156.59 70.111 156.59 156.59s-78.911
          187.343-264.471 187.343z"
        />
        <path
          fill={color}
          d="M268.605 341.115c0 18.463-14.963 33.428-33.428 33.428s-33.428-14.963-33.428-33.428c0-18.463 14.963-33.428
          33.428-33.428s33.428 14.963 33.428 33.428z"
        />
        <path
          fill={color}
          d="M471.85 341.115c0 18.463-14.963 33.428-33.428 33.428s-33.428-14.963-33.428-33.428c0-18.463
            14.963-33.428 33.428-33.428s33.428 14.963 33.428 33.428z"
        />
        <path
          fill={color}
          opacity="0.8"
          d="M414.381 679.357c258.439 53.219 268.527 146.398 268.527
          256.271 0 56.928-0.018 88.37-0.018 88.37-377.142 0-682.88-256.761-682.88-633.902
          0 0 60.789 216.462 414.368 289.262z"
        />
        <path
          fill={color}
          opacity="0.4"
          d="M384.336 645.274c12.518 2.98 16.662 3.689 30.045 6.294 208.402 40.377 250.764 109.216
            268.527 164.142l0.043-386.557c-37.668 152.919-266.927 215.186-298.616 216.121z"
        />
      </SvgIcon>
    );
  }

  if (isColumn) {
    return (
      <SvgIcon
        title="clevergig"
        height={height}
        width={width}
        css={wrapCssClass}
        viewBox="0 0 2678 1690"
      >
        <path
          fill={color}
          d="M1520.33,58.75c-88.57-96.2-48.81,43.91-198.82,43.91s-110.25-140.11-198.82-43.91
            c-68.88,74.83-110.26,159.97-110.26,264.56c0,0,0.12,0.17,0.12,0.26c1.37,7.34,37.75,195.77,309.13,272.34
            c5.06-0.47,308.85-36.78,308.85-272.6h0.07C1630.59,218.72,1589.21,133.58,1520.33,58.75z M1321.29,492.75
            c-167.92,0-239.35-91.28-239.35-169.54s63.44-141.71,141.71-141.71c37.86,0,72.22,14.88,97.64,39.06
            c25.42-24.19,59.78-39.06,97.64-39.06c78.26,0,141.71,63.45,141.71,141.71S1489.21,492.75,1321.29,492.75z"
        />
        <circle fill={color} cx="1225.18" cy="333.71" r="30.25" />
        <circle fill={color} cx="1409.12" cy="333.71" r="30.25" />
        <path
          fill={color}
          opacity=".8"
          d="M1387.35,639.79c233.87,48.16,243,132.47,243,231.91c0,51.51-0.02,79.97-0.02,79.97
          c-341.29,0-617.96-232.35-617.96-573.64C1012.37,378.02,1067.39,573.91,1387.35,639.79z"
        />
        <path
          fill={color}
          opacity=".4"
          d="M1360.16,608.95c11.34,2.7,15.08,3.35,27.19,5.69c188.59,36.54,226.93,98.83,243,148.55l0.05-349.81
          C1596.3,551.74,1388.85,608.09,1360.16,608.95z"
        />
        <g>
          <path
            fill={color}
            d="M198.64,1523.22h-6.17c-43.91,0-81.64-16.23-113.2-48.7c-31.56-32.48-47.34-70.55-47.34-114.23
            c0-43.67,16-81.4,48.02-113.2c32.01-31.79,71.35-47.68,118-47.68s86.45,17.61,119.38,52.83v34.99
            c-14.19-21.03-32.14-37.73-53.86-50.08c-21.73-12.35-44.48-18.53-68.26-18.53c-37.97,0-71,14.07-99.13,42.2
            c-28.13,28.12-42.19,61.64-42.19,100.51c0,38.88,14.06,72.38,42.19,100.51c28.13,28.13,61.17,42.19,99.13,42.19
            c23.78,0,46.54-6.17,68.26-18.53c21.72-12.35,39.67-29.26,53.86-50.76v35.67C285.31,1505.63,245.74,1523.22,
            198.64,1523.22z"
          />
          <path
            fill={color}
            d="M198.64,1527.95h-6.17c-45.04,0-84.27-16.87-116.6-50.14c-32.3-33.23-48.67-72.77-48.67-117.53
            c0-44.78,16.62-83.99,49.42-116.55c32.77-32.55,73.6-49.05,121.33-49.05c47.79,0,89.11,18.28,122.83,
            54.32l1.27,1.36v52.31
            l-8.65-12.81c-13.72-20.33-31.3-36.69-52.28-48.62c-20.91-11.88-43.09-17.91-65.92-17.91c-36.48,
            0-68.71,13.73-95.79,40.81
            c-27.08,27.07-40.8,59.76-40.8,97.16c0,37.4,13.73,70.09,40.8,97.16c27.08,27.08,59.3,40.81,95.79,40.81
            c22.85,0,45.02-6.03,65.92-17.91c20.98-11.93,38.56-28.5,52.26-49.26l8.67-13.14v53.24l-1.23,1.35
            C288.04,1509.66,246.93,1527.95,198.64,1527.95z"
          />
        </g>
        <g>
          <path
            fill={color}
            d="M499.26,1501.27v19.22c-32.48,0-60.14-10.86-83.01-32.6c-22.88-21.71-34.3-49.49-34.3-83.35V999.77h21.96
            v404.78c0,27.9,9.26,51,27.79,69.29C450.21,1492.13,472.73,1501.27,499.26,1501.27z"
          />
          <path
            fill={color}
            d="M503.98,1525.21h-4.73c-33.58,0-62.6-11.41-86.26-33.9c-23.74-22.52-35.78-51.72-35.78-86.77v-409.5h31.41
            v409.5c0,26.46,8.88,48.64,26.38,65.92c17.51,17.3,39.13,26.08,64.25,26.08h4.73V1525.21z"
          />
        </g>
        <g>
          <path
            fill={color}
            d="M687.37,1522.54h-6.17c-43.46,0-81.3-16.35-113.54-49.06c-32.25-32.69-48.37-70.77-48.37-114.23
            c0-43.45,16-81.06,48.02-112.85c32.01-31.78,71.58-47.68,118.69-47.68c47.1,0,87.58,18.76,121.43,56.26l-215.42,
            212.67
            c29.73,23.79,58.31,35.68,85.76,35.68c27.44,0,52.02-6.28,73.75-18.86c21.72-12.57,39.67-29.39,
            53.85-50.42v34.99
            C772.89,1504.71,733.56,1522.54,687.37,1522.54z M680.16,1217.94c-36.36,0-68.61,14.06-96.74,42.19
            c-28.12,28.13-42.19,60.49-42.19,97.07c0,36.6,12.11,68.61,36.36,96.05l199.64-198.27
            C748.87,1230.29,716.52,1217.94,680.16,1217.94z"
          />
          <path
            fill={color}
            d="M687.37,1527.27h-6.17c-44.57,0-83.9-16.98-116.91-50.47c-33-33.45-49.73-73-49.73-117.55
            s16.63-83.64,49.43-116.21C596.76,1210.5,637.81,1194,686,1194c48.27,0,90.31,19.45,124.94,57.82l3.03,
            3.35l-214.81,212.07
            c27.23,20.82,53.65,31.36,78.61,31.36c26.48,0,50.5-6.13,71.38-18.23c20.99-12.15,38.59-28.63,
            52.31-48.98l8.65-12.83v52.29
            l-1.23,1.35C775.6,1508.75,734.73,1527.27,687.37,1527.27z M680.17,1222.67c-34.9,0-66.32,13.73-93.4,40.81
            c-27.08,27.09-40.81,58.62-40.81,93.73c0,33.56,10.73,63.51,31.92,89.1l192.36-191.04
            C743.81,1233.63,713.53,1222.67,680.17,1222.67z"
          />
        </g>
        <g>
          <path
            fill={color}
            d="M1138.91,1205.58h19.89l-151.61,317.64l-152.31-317.64h24.01l130.35,271L1138.91,1205.58z"
          />
          <path
            fill={color}
            d="M1007.2,1534.17l-159.83-333.32h34.49l127.37,264.79l126.7-264.79h30.38L1007.2,1534.17z"
          />
        </g>
        <g>
          <path
            fill={color}
            d="M1377.79,1522.54h-6.17c-43.46,0-81.3-16.35-113.54-49.06c-32.25-32.69-48.37-70.77-48.37-114.23
            c0-43.45,16-81.06,48.02-112.85c32.01-31.78,71.58-47.68,118.69-47.68c47.1,0,87.58,18.76,121.43,
            56.26l-215.42,212.67
            c29.73,23.79,58.31,35.68,85.76,35.68c27.44,0,52.02-6.28,73.75-18.86c21.72-12.57,
            39.67-29.39,53.85-50.42v34.99
            C1463.3,1504.71,1423.98,1522.54,1377.79,1522.54z M1370.58,1217.94c-36.36,0-68.61,14.06-96.74,42.19
            c-28.12,28.13-42.19,60.49-42.19,97.07c0,36.6,12.11,68.61,36.36,96.05l199.64-198.27
            C1439.29,1230.29,1406.94,1217.94,1370.58,1217.94z"
          />
          <path
            fill={color}
            d="M1377.78,1527.27h-6.17c-44.56,0-83.89-16.98-116.91-50.47c-33-33.45-49.73-73-49.73-117.55
            s16.62-83.65,49.42-116.21c32.79-32.55,73.84-49.05,122.02-49.05c48.27,0,90.31,19.45,124.94,57.82l3.03,
            3.35l-214.81,212.07
            c27.23,20.82,53.65,31.36,78.61,31.36c26.48,0,50.5-6.13,71.38-18.23c20.99-12.15,38.59-28.63,
            52.3-48.98l8.65-12.83v52.29
            l-1.23,1.35C1466.02,1508.75,1425.14,1527.27,1377.78,1527.27z M1370.58,1222.67c-34.89,
            0-66.31,13.73-93.39,40.81
            c-27.08,27.08-40.81,58.61-40.81,93.73c0,33.56,10.73,63.51,31.91,89.1l192.37-191.04
            C1434.23,1233.63,1403.94,1222.67,1370.58,1222.67z"
          />
        </g>
        <g>
          <path
            fill={color}
            d="M1683.2,1217.25c-24.25,0-45.62,9.16-64.15,27.44c-18.52,18.29-27.79,39.57-27.79,
            63.81v205.81h-21.96v-308.73
            h21.96v45.97c8.23-15.54,21.03-28.35,38.42-38.42c17.38-10.05,36.81-15.09,58.32-15.09c21.49,0,42.07,7.32,
            61.74,21.96v27.44
            C1729.62,1227.32,1707.44,1217.25,1683.2,1217.25z"
          />
          <path
            fill={color}
            d="M1596,1519.04h-31.41v-318.18H1596v34.79c8.09-10.32,18.58-19.23,31.32-26.61
            c18.05-10.44,38.46-15.73,60.68-15.73c22.43,0,44.15,7.7,64.57,22.9l1.9,
            1.42v41.22l-8.07-8.07c-19.12-19.11-40.38-28.8-63.2-28.8
            c-22.83,0-43.29,8.77-60.83,26.08c-17.5,17.29-26.37,37.63-26.37,60.44V1519.04z"
          />
        </g>
        <g>
          <path
            fill={color}
            d="M2101.14,1205.58h21.96v279.91c0,53.06-14.65,94.9-43.91,125.55c-29.27,30.63-67.69,45.96-115.25,45.96
            c-26.07,0-51.45-5.95-76.15-17.84c-24.7-11.89-43.91-27.44-57.63-46.65v-31.55c11.89,21.95,30.53,
            40.24,55.91,54.88
            c25.38,14.63,51.33,21.95,77.87,21.95c40.24,0,73.17-13.61,98.79-40.82c25.61-27.22,38.42-64.38,
            38.42-111.49v-45.96
            c-12.35,25.15-31.8,45.28-58.32,60.37c-26.53,15.09-53.97,22.64-82.33,22.64c-44.83,0-83.24-15.89-115.25-47.68
            c-32.02-31.79-48.02-69.86-48.02-114.23c0-44.36,16-82.43,48.02-114.23c32.01-31.78,70.43-47.68,115.25-47.68
            c29.73,0,57.51,7.44,83.36,22.3c25.84,14.87,44.94,35.33,57.29,61.4V1205.58z M1819.17,1360.63c0,38.88,14.06,
            72.38,42.19,100.51
            c28.13,28.13,61.51,42.19,100.17,42.19c38.64,0,71.69-14.06,99.13-42.19c27.44-28.13,41.16-61.63,41.16-100.51
            c0-38.87-13.72-72.38-41.16-100.51c-27.44-28.13-60.49-42.19-99.13-42.19c-38.66,0-72.04,14.06-100.17,42.19
            C1833.23,1288.26,1819.17,1321.77,1819.17,1360.63z"
          />
          <path
            fill={color}
            d="M1963.93,1661.73c-26.66,0-52.97-6.16-78.2-18.31c-25.31-12.18-45.31-28.39-59.43-48.16l-0.88-1.23v-51.73
            l8.88,16.41c11.4,21.05,29.61,38.89,54.12,53.04c24.55,14.14,49.95,21.32,75.5,21.32c38.69,0,70.77-13.23,
            95.35-39.34
            c24.64-26.19,37.13-62.6,37.13-108.24v-28.26c-12.53,18.83-29.7,34.52-51.25,46.78c-27.13,15.43-55.62,
            23.26-84.67,23.26
            c-45.9,0-85.8-16.5-118.58-49.05c-32.79-32.55-49.42-72.12-49.42-117.59c0-45.46,16.62-85.02,49.42-117.58
            c32.79-32.55,72.69-49.05,118.58-49.05c30.4,0,59.24,7.71,85.72,22.92c20.97,12.07,37.8,27.92,50.2,
            47.24v-63.3h31.41v284.64
            c0,54.05-15.22,97.39-45.22,128.82C2052.53,1645.78,2012.6,1661.73,1963.93,1661.73z M1961.52,1222.67
            c-37.17,0-69.74,13.73-96.83,40.81c-27.08,27.08-40.8,59.77-40.8,97.16c0,37.4,13.73,70.09,40.8,97.16
            c27.08,27.08,59.66,40.81,96.83,40.81c37.15,0,69.36-13.71,95.75-40.77c26.42-27.08,39.82-59.79,39.82-97.2
            s-13.39-70.12-39.82-97.2C2030.89,1236.38,1998.68,1222.67,1961.52,1222.67z"
          />
        </g>
        <g>
          <path
            fill={color}
            d="M2231.61,1130.8c-13.27,0-19.9-6.18-19.9-18.53c0-12.35,6.63-18.52,19.9-18.52c13.26,0,
            19.89,6.17,19.89,18.52
            C2251.51,1124.63,2244.87,1130.8,2231.61,1130.8z M2242.58,1206.27v308.04h-21.95v-308.04H2242.58z"
          />
          <path
            fill={color}
            d="M2247.31,1519.04h-31.41v-317.49h31.41V1519.04z M2231.61,1135.53c-15.88,0-24.63-8.26-24.63-23.25
            c0-14.99,8.75-23.24,24.63-23.24s24.63,8.25,24.63,23.24C2256.24,1127.28,2247.49,1135.53,2231.61,
            1135.53z M2231.61,1098.49
            c-10.64,0-15.17,4.12-15.17,13.79c0,9.67,4.54,13.8,15.17,13.8s15.17-4.13,15.17-13.8
            C2246.78,1102.61,2242.25,1098.49,2231.61,1098.49z"
          />
        </g>
        <g>
          <path
            fill={color}
            d="M2626.9,1205.58h21.95v279.91c0,53.06-14.64,94.9-43.9,125.55c-29.27,30.63-67.7,45.96-115.26,45.96
            c-26.06,0-51.45-5.95-76.14-17.84c-24.71-11.89-43.91-27.44-57.64-46.65v-31.55c11.89,21.95,30.53,
            40.24,55.92,54.88
            c25.38,14.63,51.34,21.95,77.87,21.95c40.25,0,73.18-13.61,98.79-40.82c25.61-27.22,38.42-64.38,
            38.42-111.49v-45.96
            c-12.35,25.15-31.8,45.28-58.32,60.37c-26.53,15.09-53.97,22.64-82.32,22.64c-44.83,0-83.25-15.89-115.26-47.68
            c-32.02-31.79-48.02-69.86-48.02-114.23c0-44.36,16.01-82.43,48.02-114.23c32.01-31.78,70.43-47.68,115.26-47.68
            c29.72,0,57.51,7.44,83.36,22.3c25.83,14.87,44.93,35.33,57.28,61.4V1205.58z M2344.93,1360.63c0,38.88,14.06,
            72.38,42.19,100.51
            c28.13,28.13,61.51,42.19,100.16,42.19c38.64,0,71.7-14.06,99.14-42.19c27.44-28.13,41.17-61.63,41.17-100.51
            c0-38.87-13.73-72.38-41.17-100.51c-27.44-28.13-60.49-42.19-99.14-42.19c-38.65,0-72.03,14.06-100.16,42.19
            C2358.99,1288.26,2344.93,1321.77,2344.93,1360.63z"
          />
          <path
            fill={color}
            d="M2489.69,1661.73c-26.66,0-52.97-6.16-78.19-18.31c-25.3-12.18-45.3-28.38-59.43-48.16l-0.88-1.23v-51.72
            l8.88,16.4c11.41,21.06,29.62,38.9,54.12,53.04c24.55,14.14,49.95,21.32,75.5,21.32c38.7,0,70.78-13.23,
            95.35-39.34
            c24.64-26.19,37.13-62.61,37.13-108.24v-28.26c-12.53,18.83-29.71,34.52-51.25,46.78c-27.13,15.43-55.61,
            23.26-84.66,23.26
            c-45.91,0-85.81-16.5-118.6-49.05c-32.79-32.55-49.42-72.12-49.42-117.59c0-45.46,16.62-85.02,49.42-117.58
            c32.79-32.55,72.69-49.05,118.6-49.05c30.39,0,59.23,7.71,85.71,22.92c20.97,12.07,37.8,27.92,50.2,
            47.24v-63.3h31.4v284.64
            c0,54.06-15.21,97.4-45.22,128.82C2578.29,1645.78,2538.37,1661.73,2489.69,1661.73z M2487.29,1222.67
            c-37.17,0-69.74,13.73-96.82,40.81c-27.08,27.08-40.81,59.77-40.81,97.16c0,37.4,13.73,70.08,40.81,97.16
            c27.08,27.08,59.65,40.81,96.82,40.81c37.15,0,69.36-13.71,95.75-40.77c26.43-27.09,39.83-59.79,39.83-97.2
            c0-37.41-13.4-70.12-39.83-97.2C2556.65,1236.38,2524.44,1222.67,2487.29,1222.67z"
          />
        </g>
      </SvgIcon>
    );
  }

  return (
    <SvgIcon
      title="clevergig"
      height={height}
      width={width}
      css={wrapCssClass}
      viewBox="0 0 2576 732"
    >
      <g>
        <path
          fill={color}
          d="M382.7,49.54c-63.62-69.11-35.07,31.54-142.83,31.54c-107.76,0-79.21-100.65-142.83-31.54
          C47.55,103.3,17.83,164.46,17.83,239.6c0,0,0.08,0.12,0.08,0.18c0.98,5.27,27.12,140.64,222.07,195.65
          c3.63-0.34,221.87-26.42,221.87-195.83h0.05C461.91,164.46,432.18,103.3,382.7,49.54z M239.71,361.32
          c-120.63,0-171.95-65.57-171.95-121.79s45.58-101.8,101.8-101.8c27.2,0,51.88,10.69,70.14,28.06
          c18.26-17.38,42.95-28.06,70.14-28.06c56.22,0,101.8,45.58,101.8,101.8S360.34,361.32,239.71,361.32z"
        />
        <circle
          fill={color}
          cx="170.67"
          cy="247.06"
          r="21.73"
        />
        <circle
          fill={color}
          cx="302.8"
          cy="247.06"
          r="21.73"
        />
        <path
          fill={color}
          opacity=".8"
          d="M287.17,466.95c168.01,34.6,174.57,95.17,174.57,166.6c0,37.01-0.01,57.45-0.01,57.45
          C216.55,691,17.79,524.08,17.79,278.9C17.79,278.9,57.31,419.62,287.17,466.95z"
        />
        <path
          fill={color}
          opacity=".4"
          d="M267.64,444.79c8.14,1.94,10.83,2.4,19.53,4.09c135.48,26.25,163.02,71,174.57,106.71l0.03-251.3
          C437.28,403.7,288.24,444.18,267.64,444.79z"
        />
      </g>
      <g>
        <path
          fill={color}
          d="M802.89,499.02h-4.44c-31.56,0-58.68-11.66-81.36-35c-22.68-23.34-34.02-50.7-34.02-82.1
            c0-31.38,11.5-58.5,34.51-81.35c23-22.84,51.28-34.27,84.8-34.27s62.13,12.66,85.79,37.96v25.14
            c-10.2-15.11-23.1-27.12-38.71-35.99c-15.62-8.87-31.97-13.31-49.06-13.31c-27.28,0-51.03,10.11-71.25,30.33
            c-20.22,20.21-30.32,44.3-30.32,72.23c0,27.94,10.1,52.02,30.32,72.23c20.22,20.22,43.96,30.32,71.25,30.32
            c17.09,0,33.44-4.43,49.06-13.31c15.61-8.87,28.51-21.03,38.71-36.48v25.63C865.18,486.38,836.74,
            499.02,802.89,499.02z"
        />
        <path
          fill={color}
          d="M802.89,502.42h-4.44c-32.37,0-60.56-12.12-83.8-36.03c-23.21-23.88-34.98-52.3-34.98-84.46
            c0-32.18,11.95-60.36,35.52-83.76c23.55-23.39,52.89-35.25,87.2-35.25c34.34,0,64.04,13.14,88.28,39.04l0.92,
            0.98v37.59l-6.22-9.21
            c-9.86-14.61-22.5-26.37-37.57-34.94c-15.03-8.54-30.97-12.87-47.38-12.87c-26.22,0-49.38,9.87-68.84,29.33
            c-19.46,19.45-29.32,42.95-29.32,69.83c0,26.88,9.86,50.37,29.32,69.83c19.46,19.46,42.62,29.33,68.84,29.33
            c16.42,0,32.36-4.33,47.38-12.87c15.08-8.57,27.71-20.48,37.56-35.4l6.23-9.44v38.26l-0.88,0.97
            C867.14,489.27,837.59,502.42,802.89,502.42z"
        />
      </g>
      <g>
        <path
          fill={color}
          d="M1018.94,483.25v13.81c-23.34,0-43.22-7.8-59.66-23.43c-16.44-15.6-24.65-35.57-24.65-59.9V122.82h15.78
          v290.91c0,20.05,6.66,36.65,19.97,49.8C983.68,476.68,999.87,483.25,1018.94,483.25z"
        />
        <path
          fill={color}
          d="M1022.33,500.45h-3.4c-24.13,0-44.99-8.2-62-24.36c-17.06-16.19-25.71-37.17-25.71-62.36v-294.3h22.57v294.3
            c0,19.02,6.38,34.96,18.96,47.38c12.59,12.44,28.12,18.74,46.18,18.74h3.4V500.45z"
        />
      </g>
      <g>
        <path
          fill={color}
          d="M1154.13,498.53h-4.44c-31.23,0-58.43-11.75-81.6-35.26c-23.18-23.49-34.76-50.86-34.76-82.1
          c0-31.22,11.5-58.25,34.51-81.11c23.01-22.84,51.44-34.27,85.3-34.27c33.85,0,62.94,13.48,87.27,
          40.43l-154.82,152.84
          c21.36,17.1,41.91,25.64,61.63,25.64c19.72,0,37.39-4.51,53-13.56c15.61-9.03,28.51-21.12,38.7-36.24v25.14
          C1215.59,485.71,1187.32,498.53,1154.13,498.53z M1148.95,279.62c-26.13,0-49.31,10.1-69.52,30.32
          c-20.21,20.22-30.32,43.48-30.32,69.76c0,26.3,8.7,49.31,26.13,69.03l143.48-142.49C1198.33,288.49,1175.08,
          279.62,1148.95,279.62z"
        />
        <path
          fill={color}
          d="M1154.13,501.93h-4.44c-32.03,0-60.3-12.2-84.02-36.27c-23.71-24.04-35.74-52.47-35.74-84.48
          s11.95-60.11,35.52-83.52c23.56-23.39,53.06-35.25,87.69-35.25c34.69,0,64.9,13.98,89.79,41.55l2.18,
          2.41l-154.38,152.41
          c19.57,14.96,38.56,22.54,56.49,22.54c19.03,0,36.29-4.41,51.3-13.1c15.09-8.73,27.74-20.58,
          37.59-35.2l6.22-9.22v37.58l-0.88,0.97
          C1217.54,488.62,1188.16,501.93,1154.13,501.93z M1148.95,283.02c-25.08,0-47.66,9.87-67.12,29.33
          c-19.46,19.47-29.33,42.13-29.33,67.36c0,24.12,7.71,45.64,22.94,64.04l138.25-137.3C1194.69,290.9,1172.93,
          283.02,1148.95,283.02z"
        />
      </g>
      <g>
        <path
          fill={color}
          d="M1478.65,270.74h14.3l-108.96,228.28l-109.46-228.28h17.26l93.68,194.76L1478.65,270.74z"
        />
        <path
          fill={color}
          d="M1383.99,506.89l-114.87-239.55h24.79l91.54,190.3l91.05-190.3h21.83L1383.99,506.89z"
        />
      </g>
      <g>
        <path
          fill={color}
          d="M1650.32,498.53h-4.44c-31.23,0-58.43-11.75-81.6-35.26c-23.18-23.49-34.76-50.86-34.76-82.1
          c0-31.22,11.5-58.25,34.51-81.11c23-22.84,51.44-34.27,85.3-34.27c33.85,0,62.94,13.48,87.27,40.43l-154.82,152.84
          c21.36,17.1,41.91,25.64,61.63,25.64c19.72,0,37.39-4.51,53-13.56c15.61-9.03,28.51-21.12,38.7-36.24v25.14
          C1711.78,485.71,1683.52,498.53,1650.32,498.53z M1645.14,279.62c-26.13,0-49.31,10.1-69.52,30.32
          c-20.21,20.22-30.32,43.48-30.32,69.76c0,26.3,8.7,49.31,26.13,69.03l143.48-142.49C1694.52,288.49,
          1671.27,279.62,1645.14,279.62z"
        />
        <path
          fill={color}
          d="M1650.32,501.93h-4.44c-32.03,0-60.29-12.2-84.02-36.27c-23.71-24.04-35.74-52.46-35.74-84.48
          s11.95-60.12,35.52-83.52c23.56-23.39,53.07-35.25,87.69-35.25c34.69,0,64.9,13.98,89.79,41.55l2.18,
          2.41l-154.38,152.41
          c19.57,14.96,38.56,22.54,56.49,22.54c19.03,0,36.29-4.41,51.3-13.1c15.08-8.73,27.73-20.58,
          37.59-35.2l6.22-9.22v37.58l-0.88,0.97
          C1713.73,488.62,1684.35,501.93,1650.32,501.93z M1645.14,283.02c-25.08,0-47.66,9.87-67.12,29.33
          c-19.46,19.46-29.33,42.13-29.33,67.36c0,24.12,7.71,45.64,22.94,64.04l138.25-137.3C1690.88,290.9,
          1669.12,283.02,1645.14,283.02z"
        />
      </g>
      <g>
        <path
          fill={color}
          d="M1869.82,279.12c-17.43,0-32.79,6.58-46.1,19.72c-13.31,13.15-19.97,28.44-19.97,45.86v147.91h-15.78V270.74
          h15.78v33.04c5.92-11.17,15.11-20.38,27.61-27.61c12.49-7.22,26.46-10.85,41.91-10.85c15.45,0,30.24,5.26,
          44.37,15.78v19.72
          C1903.18,286.36,1887.24,279.12,1869.82,279.12z"
        />
        <path
          fill={color}
          d="M1807.15,496.01h-22.57V267.34h22.57v25.01c5.82-7.42,13.35-13.82,22.51-19.13
          c12.97-7.5,27.64-11.3,43.61-11.3c16.12,0,31.73,5.54,46.4,16.46l1.37,
          1.02v29.63l-5.8-5.8c-13.74-13.73-29.02-20.7-45.42-20.7
          c-16.41,0-31.12,6.31-43.72,18.74c-12.58,12.43-18.95,27.04-18.95,43.44V496.01z"
        />
      </g>
      <g>
        <path
          fill={color}
          d="M2170.18,270.74h15.78V471.9c0,38.13-10.53,68.21-31.56,90.23c-21.04,22.02-48.65,33.03-82.83,33.03
          c-18.74,0-36.98-4.27-54.73-12.82c-17.75-8.55-31.55-19.72-41.42-33.52v-22.68c8.54,15.78,21.94,28.92,40.19,39.44
          c18.24,10.51,36.89,15.78,55.96,15.78c28.92,0,52.59-9.78,71-29.34c18.41-19.56,27.61-46.27,27.61-80.12v-33.03
          c-8.87,18.08-22.85,32.54-41.91,43.39c-19.07,10.85-38.79,16.27-59.17,16.27c-32.22,0-59.83-11.42-82.83-34.27
          c-23.01-22.84-34.51-50.21-34.51-82.1c0-31.88,11.5-59.24,34.51-82.1c23-22.84,50.61-34.27,82.83-34.27
          c21.36,0,41.33,5.35,59.91,16.02c18.57,10.69,32.3,25.39,41.17,44.13V270.74z M1967.53,382.17c0,
          27.94,10.1,52.02,30.32,72.23
          s44.2,30.32,71.99,30.32c27.77,0,51.52-10.1,71.25-30.32s29.58-44.29,29.58-72.23c0-27.93-9.86-52.02-29.58-72.23
          s-43.47-30.32-71.25-30.32c-27.78,0-51.77,10.1-71.99,30.32S1967.53,354.24,1967.53,382.17z"
        />
        <path
          fill={color}
          d="M2071.57,598.57c-19.16,0-38.07-4.43-56.2-13.16c-18.19-8.76-32.56-20.4-42.71-34.61l-0.63-0.89v-37.18
          l6.38,11.79c8.19,15.13,21.28,27.95,38.9,38.12c17.64,10.17,35.9,15.32,54.26,15.32c27.81,0,50.86-9.51,
          68.53-28.27
          c17.71-18.82,26.69-44.99,26.69-77.79v-20.31c-9.01,13.53-21.35,24.81-36.83,33.62c-19.5,11.09-39.97,
          16.72-60.85,16.72
          c-32.99,0-61.66-11.86-85.22-35.25c-23.57-23.4-35.52-51.83-35.52-84.51c0-32.67,11.95-61.1,35.52-84.51
          c23.56-23.39,52.24-35.25,85.22-35.25c21.85,0,42.58,5.54,61.6,16.47c15.07,8.68,27.17,20.06,36.08,
          33.95v-45.49h22.57v204.57
          c0,38.85-10.94,70-32.5,92.58C2135.25,587.1,2106.55,598.57,2071.57,598.57z M2069.84,283.02c-26.71,0-50.12,
          9.87-69.59,29.33
          c-19.46,19.46-29.32,42.95-29.32,69.83c0,26.88,9.86,50.37,29.32,69.83c19.46,19.46,42.88,29.33,69.59,29.33
          c26.7,0,49.85-9.86,68.81-29.3c18.99-19.46,28.62-42.97,28.62-69.86s-9.63-50.39-28.62-69.86
          C2119.7,292.87,2096.54,283.02,2069.84,283.02z"
        />
      </g>
      <g>
        <path
          fill={color}
          d="M2263.95,217c-9.54,0-14.3-4.44-14.3-13.31s4.76-13.31,14.3-13.31c9.53,0,14.3,4.43,14.3,13.31
          S2273.48,217,2263.95,217z M2271.83,271.23v221.38h-15.78V271.23H2271.83z"
        />
        <path
          fill={color}
          d="M2275.23,496.01h-22.57V267.84h22.57V496.01z M2263.95,220.4c-11.41,0-17.7-5.93-17.7-16.71
            c0-10.77,6.29-16.71,17.7-16.71c11.41,0,17.7,5.93,17.7,16.71C2281.65,214.46,2275.36,220.4,
            2263.95,220.4z M2263.95,193.77
            c-7.64,0-10.9,2.96-10.9,9.91c0,6.95,3.26,9.92,10.9,9.92c7.64,0,10.9-2.97,10.9-9.92
            C2274.85,196.74,2271.59,193.77,2263.95,193.77z"
        />
      </g>
      <g>
        <path
          fill={color}
          d="M2548.04,270.74h15.78V471.9c0,38.13-10.52,68.21-31.55,90.23c-21.04,22.02-48.65,33.03-82.84,33.03
          c-18.73,0-36.98-4.27-54.72-12.82c-17.76-8.55-31.56-19.72-41.42-33.52v-22.68c8.55,15.78,21.94,28.92,40.19,39.44
          c18.24,10.51,36.89,15.78,55.96,15.78c28.92,0,52.59-9.78,71-29.34c18.4-19.56,27.61-46.27,27.61-80.12v-33.03
          c-8.87,18.08-22.85,32.54-41.91,43.39c-19.07,10.85-38.79,16.27-59.16,16.27c-32.22,0-59.83-11.42-82.84-34.27
          c-23.01-22.84-34.51-50.21-34.51-82.1c0-31.88,11.5-59.24,34.51-82.1c23.01-22.84,50.62-34.27,82.84-34.27
          c21.36,0,41.33,5.35,59.91,16.02c18.56,10.69,32.29,25.39,41.17,44.13V270.74z M2345.39,382.17c0,27.94,
          10.1,52.02,30.32,72.23
          c20.22,20.22,44.2,30.32,71.98,30.32c27.77,0,51.53-10.1,71.25-30.32c19.72-20.22,29.59-44.29,29.59-72.23
          c0-27.93-9.86-52.02-29.59-72.23c-19.72-20.22-43.48-30.32-71.25-30.32c-27.78,0-51.77,10.1-71.98,30.32
          C2355.5,330.16,2345.39,354.24,2345.39,382.17z"
        />
        <path
          fill={color}
          d="M2449.42,598.57c-19.16,0-38.07-4.43-56.2-13.16c-18.19-8.75-32.56-20.4-42.71-34.61l-0.63-0.89v-37.17
          l6.38,11.78c8.2,15.13,21.29,27.96,38.9,38.12c17.64,10.17,35.9,15.32,54.26,15.32c27.81,0,50.87-9.51,68.53-28.27
          c17.71-18.82,26.69-45,26.69-77.79v-20.31c-9.01,13.53-21.35,24.81-36.83,33.62c-19.5,11.09-39.97,
          16.72-60.84,16.72
          c-33,0-61.67-11.86-85.23-35.25c-23.57-23.4-35.52-51.83-35.52-84.51c0-32.67,11.95-61.1,35.52-84.51
          c23.56-23.39,52.24-35.25,85.23-35.25c21.84,0,42.57,5.54,61.6,16.47c15.07,8.68,27.17,20.06,
          36.08,33.95v-45.49h22.57v204.57
          c0,38.85-10.93,70-32.5,92.58C2513.11,587.1,2484.41,598.57,2449.42,598.57z M2447.7,283.02c-26.71,
          0-50.12,9.87-69.58,29.33
          c-19.46,19.46-29.33,42.95-29.33,69.83c0,26.88,9.87,50.37,29.33,69.83c19.46,19.46,42.87,29.33,69.58,29.33
          c26.7,0,49.85-9.86,68.81-29.3c18.99-19.47,28.62-42.97,28.62-69.86c0-26.89-9.63-50.39-28.62-69.86
          C2497.55,292.87,2474.4,283.02,2447.7,283.02z"
        />
      </g>
    </SvgIcon>
  );
};
LogoBrandIcon.propTypes = {
  color: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
  wrapCssClass: PropTypes.object,
  isColumn: PropTypes.bool,
  isLogo: PropTypes.bool,
};

export default LogoBrandIcon;
