import React from 'react';
import PropTypes from 'prop-types';

const SvgIcon = (props) => {
  const {
    title = '',
    width = '100%',
    height = '100%',
    viewBox = '0 0 32 32',
    className = '',
    wrapCssClass = {},
    children,
  } = props;
  return (
    <svg
      width={width}
      height={height}
      viewBox={viewBox}
      className={className}
      style={wrapCssClass}
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      {title && (<title id="icon-title">{title}</title>)}
      {children}
    </svg>
  );
};

SvgIcon.propTypes = {
  wrapCssClass: PropTypes.object,
  title: PropTypes.string,
  width: PropTypes.oneOfType([
    PropTypes.string.isRequired,
    PropTypes.number.isRequired,
  ]),
  height: PropTypes.oneOfType([
    PropTypes.string.isRequired,
    PropTypes.number.isRequired,
  ]),
  viewBox: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
};

export default SvgIcon;
