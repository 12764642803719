/* eslint-disable no-underscore-dangle */
const environment = {
  development: {
    isProduction: false,
  },
  production: {
    isProduction: true,
  },
}[process.env.NODE_ENV || 'development'];

const hosting = {
  live: {
    isLive: true,
    isStage: false,
  },
  stg: {
    isLive: false,
    isStage: true,
  },
  localhost: {
    isLive: false,
    isStage: false,
  }
}[window.__ENV.REACT_APP_HOST_ENV || 'localhost'];

export default ({
  protocolType: window.__ENV.REACT_APP_HTTPS === 'true' ? 'https' : 'http',
  host: window.__ENV.REACT_APP_HOST,
  port: process.env.NODE_ENV !== 'development' ? window.__ENV.REACT_APP_PORT : 3333,
  fapiHost: window.__ENV.REACT_APP_FAPI_HOST,
  fapiPort: window.__ENV.REACT_APP_FAPI_PORT,
  apiHost: window.__ENV.REACT_APP_API_HOST,
  apiVersion: window.__ENV.REACT_APP_API_VERSION,
  onboardingHost: window.__ENV.REACT_APP_ONBOARDING_HOST,
  marketPage: window.__ENV.REACT_APP_MARKET_PAGE,
  sentryDNS: window.__ENV.REACT_APP_SENTRY_DNS,
  google: {
    clientId: window.__ENV.REACT_APP_GOOGLE_CLIENT_ID,
    clientSecret: window.__ENV.REACT_APP_GOOGLE_CLIENT_SECRET,
    google_api_key: window.__ENV.REACT_APP_GOOGLE_API_KEY,
  },
  linkedin: {
    clientId: window.__ENV.REACT_APP_LINKEDIN_CLIENT_ID,
    clientSecret: window.__ENV.REACT_APP_LINKEDIN_CLIENT_SECRET,
  },
  isLogisticForce: window.__ENV.REACT_APP_SUB_ENV === 'logistic_force',
  app: {
    description: 'clevergig',
    head: {
      titleTemplate: 'clevergig: %s',
      meta: [
        {
          name: 'description',
          content: `Een betaalbare personeelsplanner voor uitzendbureaus, retail-, horeca- en
         productiebedrijven. Plan met het grootste gemak je team in. Vraag nu de demo aan.`
        },
        { name: 'google-site-verification', content: 'KDaX80AYSUMnCzqBVKu7SjaCgxaAT3t4i69HAZDC_3Y' },
        { charset: 'utf-8' },
        { property: 'og:site_name', content: 'clevergig' },
        { property: 'og:image', content: 'https://clevergig.nl/img/homepage/keyvisual-img.jpg' },
        { property: 'og:type', content: 'website' },
        { property: 'og:site_name', content: 'clevergig.nl' },
        { property: 'og:url', content: 'https://www.clevergig.nl/' },
        { property: 'og:title', content: 'Planning software, betaalbare personeelsplanner, roosterplanner 2.0' },
        {
          property: 'og:description',
          content: `Een betaalbare personeelsplanner voor uitzendbureaus, retail-, horeca- en productiebedrijven.
          Plan met het grootste gemak je team in. Vraag nu de demo aan.`,
        },
        { property: 'og:card', content: 'summary' },
        { property: 'og:site', content: '@brain-angency' },
        { property: 'og:creator', content: '@brain-angency' },
        { property: 'og:image:width', content: '200' },
        { property: 'og:image:height', content: '200' },
      ],
    },
  },
  ...environment,
  ...hosting
});
