import React from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/react';

const Button = (props) => {
  const {
    children = '',
    title = '',
    className = '',
    wrapCssObj = {},
    attr = {},
    disabled = false,
    onClick,
  } = props;

  const wrapCss = css({
    ...wrapCssObj,
  });

  return (
    <button
      css={wrapCss}
      type="button"
      className={className}
      title={title}
      disabled={disabled}
      {...attr}
      onClick={onClick}
    >
      {children || title}
    </button>
  );
};

Button.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string,
  className: PropTypes.string,
  wrapCssObj: PropTypes.object,
  attr: PropTypes.object,
  disabled: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
};

export default Button;
